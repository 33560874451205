<template>
  <CRow>


    <CCol col="12" md="12">
      <CCard v-if="this.validaDocumentosAceptado == 0 || this.validaDocumentosAceptado == 2">
        <CCardHeader>
          <CIcon name="cil-justify-center"/> Atención <small> lee cuidadosamente las indicaciónes señaladas</small>
        </CCardHeader>
        <CCardBody>
          <CAlert show color="success">

            <div class="wrapper fadeInDown">
              
  <!-- solicitudInscripcion, certificadoBachillerato, examenMedico, cartaConducta, comprobanteDomicilio,  folioSuredsu,  codigoEtica -->

              <p><b> <h4> Una vez que cuentes con la siguiente documentación escaneada (por favor no subas fotografías de celular), súbela a continuación desde tu laptop o computadora de escritorio.</h4> </b></p>
              <p><b>1. Solicitud de inscripción</b> (archivo adjunto a este correo), debidamente contestada y colocar una fotografía tamaño infantil reciente en donde se indica. 
                
                <p><input class="btn btn-outline-primary" type="file" accept=".pdf" id="solicitudInscripcion" ref="solicitudInscripcion" v-on:change="onChangeSolicitudInscripcion()"/>              
                <!-- <span v-show="solicitudInscripcion!=''" class="badge badge-pill badge-info">OK</span> -->
                      <span v-show="solicitudInscripcion!=''" class="badge badge-pill badge-success">OK</span>
               </p>

          



              <p><b>2. Certificado de bachillerato</b> debidamente legalizado (solo en caso de ser necesario), escaneado en tamaño carta o constancia de terminación de estudios indicando que tu certificado se encuentra en trámite. Escaneado en formato PDF.
                <p>
           
                  <input class="btn btn-outline-primary" type="file" accept=".pdf" id="certificadoBachillerato" ref="certificadoBachillerato" v-on:change="onChangeCertificadoBachillerato()"/>
                 <span v-show="certificadoBachillerato!=''" class="badge badge-pill badge-success">OK</span>
                </p>
              <p><b>3.Examen médico general expedido por institución pública</b> (IMSS, Cruz roja, ISSSTE, SEDENA, INSABI, No se aceptan los expedidos por laboratorios particulares) y que indique grupo sanguíneo. Escaneado en formato PDF.
                <p>
                  <input class="btn btn-outline-primary" type="file" accept=".pdf" id="examenMedico" ref="examenMedico" v-on:change="onChangeExamenMedico()"/>
                <span v-show="examenMedico!=''" class="badge badge-pill badge-success">OK</span>
                </p>
              <p><b>4. Carta de buena conducta emitida por bachillerato de procedencia.</b> Escaneado en formato PDF.
                <p><input class="btn btn-outline-primary" type="file" accept=".pdf" id="cartaConducta" ref="cartaConducta" v-on:change="onChangeCartaConducta()"/>
                   <span v-show="cartaConducta!=''" class="badge badge-pill badge-success">OK</span>
                    </p>
              <p><b>5. Comprobante de domicilio </b> no mayor a 3 meses de expedición. Escaneado en formato PDF.
                <p><input class="btn btn-outline-primary" type="file" accept=".pdf" id="comprobanteDomicilio" ref="comprobanteDomicilio" v-on:change="onChangeComprobanteDomicilio()"/>
                   <span v-show="comprobanteDomicilio!=''" class="badge badge-pill badge-success">OK</span>
                   </p>
              <p><b>6. Comprobante de folio SUREDSU </b>, en caso de no tenerlo favor de solicitarlo en http://suredsu.guanajuato.gob.mx
                <p><input class="btn btn-outline-primary" type="file" accept=".pdf" id="folioSuredsu" ref="folioSuredsu" v-on:change="onChangeFolioSuredsu()"/>
                  <span v-show="folioSuredsu!=''" class="badge badge-pill badge-success" >OK</span>
                  </p>

              <p><b>7.Leer documento adjunto "código de ética de la UPB”, contesta la "constancia del código de ética"</b> (archivo adjunto a este correo) y escanea en formato PDF.
                <p><input class="btn btn-outline-primary" type="file" accept=".pdf" id="codigoEtica" ref="codigoEtica" v-on:change="onChangeCodigoEtica()" />
                    <span id="sapn_ce"></span>
                </p>

                    
              <p>&nbsp;</p>
              <!-- <p>La universidad validará tu expediente de ficha y en caso de cumplir con todos los requisitos te enviará la ficha de examen de admisión y la información necesaria para el día de la aplicación.</p> -->
             <!-- color='primary' -->
               <CCol sm="12" style="text-align: center; margin-top: 20px;">
              <CButton  :disabled="solicitudInscripcion&&certificadoBachillerato&&examenMedico&&cartaConducta&&comprobanteDomicilio&&folioSuredsu&&codigoEtica ==''" size="sm" color="primary" @click="uploadAll()">{{txt_btn}}</CButton>
                 </CCol>
               
            </div>
            <hr>
            <p class="mb-0">

            </p>
          </CAlert>
        </CCardBody>
      </CCard>
      <CCard v-if="this.validaDocumentosAceptado == 1">
        <CCardHeader>
          <strong>Expediente en revision</strong>

        </CCardHeader>

        <CCardBody>
          <!-- Bootstrap Vue has some problems with Inline forms that's why we use some standard bootstrap classes -->
          <CForm inline>
            <CRow>
              <CCol sm="12">
                <small> su expediente esta en revisión, se le notificara por correo en caso de ser aprobado/rechazado
                  &nbsp;</small>
                <br/>
              </CCol>
              <!-- <CCol sm="12" style="text-align: center">
                <p>
                  <CButton color="success" @click="salir()" block>Salir</CButton>
                </p>

              </CCol> -->

            </CRow>


          </CForm>
        </CCardBody>
        <CCardFooter>
        </CCardFooter>
      </CCard>
    </CCol>

  </CRow>
</template>


<script>

export default {
  // name: 'Alerts',
    name:'cargaDocumentosAceptado',
  data () {
    return {
      // activo_btn:true,
      sapn_ce:"",
      txt_btn: "Enviar Documentos",
      id:localStorage.getItem('id'),
      dismissSecs: 10,
      dismissCountDown: 10,
      alert1: true,
      alert2: true,
      usersData: [],

      solicitudInscripcion: '',
      certificadoBachillerato: '',
      examenMedico: '',
      cartaConducta: '',
      comprobanteDomicilio: '',
      folioSuredsu: '',
      codigoEtica: '',
      validaDocumentosAceptado: localStorage.getItem('validaDocumentosAceptado')
    }
  },
  // components: {
  // CFormInput

  // },
  created(){
    this.get_referencias();
  },
  computed:{
    // isDisabled: function(){
    //   return !this.activo_btn;
    // }
  },
  methods: {
    //acta,curp,comprobante,certificado,pago,ceneval
    onChangeSolicitudInscripcion(){
      this.solicitudInscripcion = this.$refs.solicitudInscripcion.files[0];
    },
    onChangeCertificadoBachillerato(){
      this.certificadoBachillerato = this.$refs.certificadoBachillerato.files[0];
    },
    onChangeExamenMedico(){
      this.examenMedico = this.$refs.examenMedico.files[0];
    },
    onChangeCartaConducta(){
      this.cartaConducta = this.$refs.cartaConducta.files[0];
    },
    onChangeComprobanteDomicilio(){
      this.comprobanteDomicilio = this.$refs.comprobanteDomicilio.files[0];
    },
    onChangeFolioSuredsu(){
      this.folioSuredsu = this.$refs.folioSuredsu.files[0];
    },
    onChangeCodigoEtica(){
      this.codigoEtica=this.$refs.codigoEtica.files[0];
      this.sapn_ce=document.getElementById("sapn_ce").innerHTML = '<span class="badge badge-pill badge-success">OK</span>';        

    },
    uploadAll(){
      // this.activo_btn = false;
          this.txt_btn =  "Enviando...";
      let formData = new FormData();
      formData.append('solicitudInscripcion', this.solicitudInscripcion);
      formData.append('certificadoBachillerato', this.certificadoBachillerato);
      formData.append('examenMedico', this.examenMedico);
      formData.append('cartaConducta', this.cartaConducta);
      formData.append('comprobanteDomicilio', this.comprobanteDomicilio);
      formData.append('folioSuredsu', this.folioSuredsu);
      formData.append('codigoEtica', this.codigoEtica);

      this.$http.post('https://cdn.upbicentenario.edu.mx/api/uploadAdmisionAceptados/' + this.id,
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }
      ).then(response => {

        if(response.data.mensaje == '1'){
          this.alerta_txt = "se han guardado los datos correctamente";
          this.warningModal = true;
          // this.validaDocumentos2 = 1
           this.validaDocumentosAceptado = 1 

        }else{
          this.alerta_txt = response.data.mensaje;
          this.warningModal = true;
        }
      });


    },
    get_referencias(){
      this.$http.get("referenciasCandidato/" + this.id).then(
              response => {
                // console.log(response.data)
                this.usersData = response.data.referencias;
                //console.log(this.kardex);
              },
              error => {
                this.StepError(error);
              }
      );
    },
    countDownChanged (dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert () {
      this.dismissCountDown = this.dismissSecs
    },
    showDismissibleAlerts () {
      ['alert1', 'alert2'].forEach(alert => this[alert] = true)
    }
    // salir(){
    //   this.$router.push('/pages/login')
    // },
  }
}
</script>
